import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import couchdbUtil from '../lib/couchdbUtil';
import { fetchAllProceduresMetadata, fetchProceduresMetadata, syncAllProcedureData } from '../contexts/proceduresSlice';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import useProfile from '../hooks/useProfile';

const useMasterProcedureListHelpers = () => {
  const { services } = useDatabaseServices();
  const { isOfflineProcedureDataEnabled } = useProfile();
  const dispatch = useDispatch();

  // Sync master procedure list.
  const syncMasterProcedureList = useCallback(
    async (changes) => {
      if (!services.procedures) {
        return;
      }
      try {
        let results;
        const updates = changes?.results || changes?.data;
        if (updates) {
          const docs = couchdbUtil.getChangedDocs(updates);
          results = await dispatch(
            fetchProceduresMetadata({
              services,
              docs,
            })
          ).unwrap();
        } else {
          results = await dispatch(fetchAllProceduresMetadata({ services })).unwrap();
        }

        if (!isOfflineProcedureDataEnabled) {
          return;
        }

        // Sync procedure data for offline use (full procedure and images).
        dispatch(syncAllProcedureData(results.teamId, results.proceduresMetadata));
      } catch {
        // Ignore errors, we may be offline.
      }
    },
    [services, dispatch, isOfflineProcedureDataEnabled]
  );

  return { syncMasterProcedureList };
};

export default useMasterProcedureListHelpers;
