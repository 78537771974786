export const COUCHDB_URL = window.COUCHDB_URL || process.env.REACT_APP_COUCHDB_URL || 'http://localhost:5984';
export const API_URL = window.API_URL || process.env.REACT_APP_EPSILON3_AUTH_API_URL || 'http://localhost:3001';
export const EXPR_API_URL = process.env.REACT_APP_EPSILON3_API_URL || 'http://localhost:5000';

// To avoid unintentional enables, consider enable to be the string '1' exactly.
export const FEATURE_COSMOS_ENABLED = process.env.REACT_APP_FEATURE_COSMOS_ENABLED === '1';
export const FEATURE_OFFLINE_RUN_ACTIONS_ENABLED = process.env.REACT_APP_FEATURE_OFFLINE_RUN_ACTIONS === '1';
export const FEATURE_WORKSPACES_ENABLED = process.env.REACT_APP_FEATURE_WORKSPACES_ENABLED === '1';
export const FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED =
  process.env.REACT_APP_FEATURE_RUN_PARTICIPANT_TOGGLE_ENABLED === '1';
export const FEATURE_EDIT_PROCEDURE_OPENS_EXPANDED =
  process.env.REACT_APP_FEATURE_EDIT_PROCEDURE_OPENS_EXPANDED === '1';
export const FEATURE_RUN_STEP_EDITS_ENABLED = process.env.REACT_APP_FEATURE_RUN_STEP_EDITS_ENABLED === '1';

export const INTERCOM_APP_ID = window.INTERCOM_API_ID || process.env.REACT_APP_INTERCOM_APP_ID;
export const MIXPANEL_TOKEN = window.MIXPANEL_TOKEN || process.env.REACT_APP_MIXPANEL_TOKEN;

export const SUPPORT_URL = 'https://www.epsilon3.io/support';
export const CHANGELOG_URL = 'https://www.epsilon3.io/behind-the-console/category/Changelog';
export const DOCS_URL = window.DOCS_URL || 'https://docs.epsilon3.io';

export const FLASH_MSG_MS = 5000;
export const SYNC_BATCH_SIZE = parseInt(process.env.REACT_APP_SYNC_BATCH_SIZE || '100');
export const SYNC_ATTACHMENTS_BATCH_SIZE = parseInt(process.env.REACT_APP_SYNC_ATTACHMENTS_BATCH_SIZE || '25');

export const OPERATIONS_PAGE_SIZE = parseInt(process.env.REACT_APP_OPERATIONS_PAGE_SIZE || '50');

// Convert to true/false
export const INTERCOM_ENABLED = !!INTERCOM_APP_ID;

export const APM_ACTIVE = process.env.REACT_APP_APM_ACTIVE === 'true';
export const NEW_LOGIN_ACTIVE = process.env.REACT_APP_PG_AUTH_ENABLED === 'true';
export const APM_HOST = window.APM_HOST || process.env.REACT_APP_APM_HOST;
export const APM_SERVICE = process.env.REACT_APP_APM_SERVICE || 'procedures-react';
export const APM_ENVIRONMENT = window.APM_ENVIRONMENT || process.env.REACT_APP_APM_ENVIRONMENT || process.env.NODE_ENV;
export const APM_LOG_LEVEL = window.APM_LOG_LEVEL || process.env.REACT_APP_APM_LOG_LEVEL || 'error';

export const RELEASE_VERSION = window.SERVICE_VERSION || process.env.REACT_APP_RELEASE_VERSION;
export const LICENSE_IS_DEMO = window.IS_DEMO || process.env.REACT_APP_LICENSE_IS_DEMO === '1';

const TUTORIAL_URL_BASE = 'https://support.epsilon3.io/en/collections';
export const INSIGHTS_TUTORIAL_URL = `${TUTORIAL_URL_BASE}/4048700-reporting-and-analytics`;
export const BUILDS_TUTORIAL_URL = `${TUTORIAL_URL_BASE}/4067691-builds`;
export const SCHEDULE_TUTORIAL_URL = `${TUTORIAL_URL_BASE}/4067690-schedules`;
export const STORAGE_TUTORIAL_URL = `${TUTORIAL_URL_BASE}/4067692-data-analysis`;
