import { useRealtimeContext } from '../contexts/RealtimeContext';
import { useCallback, useEffect, useRef, useState } from 'react';
import {
  EntityType,
  EventMessage,
  RealtimeData,
  realtimeValueType,
} from 'shared/lib/types/realtimeUpdatesTypes';
import { isEmpty } from 'lodash';
import realtime from '../lib/realtime';
import { useDatabaseServices } from '../contexts/DatabaseContext';
import { Observer } from '../api/realtime';

export interface useRealtimeUpdatesProps {
  entityType: EntityType;
  entityId?: string;
  mergeFunction?: <T extends RealtimeData>(
    A: Array<T>,
    B: Array<T>
  ) => Array<T>;
  initialGetter: <T extends RealtimeData>() => Promise<realtimeValueType<T>>;
  enabled: boolean;
  singleEntity: boolean;
}

type useRealtimeUpdatesReturns<T extends RealtimeData> = {
  realtimeData: realtimeValueType<T> | undefined;
  isLoading: boolean;
};

/**
 * Hook for collecting a value using postgres realtime updates.
 * Accounts for initial get and merging of subsequent updates into the in-memory value.
 */
const useRealtimeUpdates = <T extends RealtimeData>({
  entityType,
  entityId,
  mergeFunction = realtime.mergeByIdAndTime,
  initialGetter,
  enabled = true,
  singleEntity = false,
}: useRealtimeUpdatesProps): useRealtimeUpdatesReturns<T> => {
  const { realtimeService } = useRealtimeContext();
  const { currentTeamId } = useDatabaseServices();
  const realtimeObserverRef = useRef<Observer | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [realtimeData, setRealtimeData] = useState<
    realtimeValueType<T> | undefined
  >(singleEntity ? undefined : []);

  /**
   * This function will merge items to ensure the items are displayed
   * per the rules in the merge function.
   */
  const mergeValue = useCallback(
    (newValue: realtimeValueType<T>) => {
      if (!singleEntity) {
        setRealtimeData((value) =>
          mergeFunction(value as Array<T>, newValue as Array<T>)
        );
      } else {
        setRealtimeData(newValue[0]);
      }
    },
    [mergeFunction, singleEntity]
  );

  /**
   * Clear the data initially, so that if the team changes, old team data will not be used.
   * Then do the initial GET.
   */
  useEffect(() => {
    if (!enabled) {
      return;
    }
    setRealtimeData(singleEntity ? undefined : []);
    setIsLoading(true);
    initialGetter<T>()
      .then((newValue) => {
        mergeValue(newValue);
      })
      .finally(() => {
        setIsLoading(false);
      });
    // Include currentTeamId as a dependency so that data will be cleared when switching teams.
  }, [enabled, initialGetter, mergeValue, singleEntity, currentTeamId]);

  /**
   * If an item is inserted, add it to the realtime value.
   * If an item is updated, update it in the realtime value.
   */
  const handleEvent = useCallback(
    (data: EventMessage) => {
      if (isEmpty(data.data) || !['UPDATE', 'INSERT'].includes(data.action)) {
        return;
      }

      mergeValue(data.data as realtimeValueType<T>);
    },
    [mergeValue]
  );

  useEffect(() => {
    if (!enabled || !initialGetter || !realtimeService) {
      return;
    }
    realtimeObserverRef.current = realtimeService.observeEvent(
      handleEvent,
      entityType,
      entityId
    );
    return () => {
      realtimeObserverRef.current?.cancel();
    };
  }, [
    handleEvent,
    realtimeService,
    initialGetter,
    entityType,
    entityId,
    enabled,
    // Include currentTeamId as a dependency so that a new observer will be created when switching teams.
    currentTeamId,
  ]);

  return { realtimeData, isLoading };
};

export default useRealtimeUpdates;
