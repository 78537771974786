import React, { ReactNode, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import couchdbUtil, { CouchDBChangeResult, CouchDBChanges } from '../lib/couchdbUtil';
import { fetchAllProceduresMetadata, fetchProceduresMetadata } from './proceduresSlice';
import { useDatabaseServices } from './DatabaseContext';
import { io, Socket } from 'socket.io-client';
import { API_URL } from '../config';
import { useUserInfo } from './UserContext';
import { getAllTeamIdsFromSession } from '../api/superlogin';
import RealtimeService, { Observer } from '../api/realtime';
import apm from '../lib/apm';
import { EventMessage } from 'shared/lib/types/realtimeUpdatesTypes';
import { POSTGRES_READ_WRITE_PROCEDURES_ENABLED } from 'shared/lib/types/api/settings/modules/models';

export interface RealtimeContextValues {
  realtimeService: undefined | RealtimeService;
}
type RealtimeServices = {
  [teamId: string]: RealtimeService;
};
const RealtimeContext = React.createContext<RealtimeContextValues | undefined>(undefined);

const createRealtimeServices = (teamIds: Array<string> | null, socket: Socket): RealtimeServices | undefined => {
  if (!teamIds) {
    return undefined;
  }

  const realtimeServices: RealtimeServices = {};

  teamIds.forEach((teamId) => {
    realtimeServices[teamId] = new RealtimeService(teamId, socket);
  });

  return realtimeServices;
};

// Enables realtime syncing of data between API and the redux store.
const RealtimeProvider = ({ children }: { children: ReactNode }) => {
  const { services, currentTeamId } = useDatabaseServices();
  const [realtimeServices, setRealtimeServices] = useState<RealtimeServices | undefined>(undefined); // Use one state for both currentTeamId and teamsServices to avoid double rendering when setting both.

  const realtimeService = useMemo(() => {
    if (!realtimeServices || !currentTeamId) {
      return undefined;
    }

    return realtimeServices[currentTeamId];
  }, [realtimeServices, currentTeamId]);
  const dispatch = useDispatch();
  const { userInfo } = useUserInfo();

  // To accomodate responses from both couchdb and Epsilon3's realtime framework
  const getChanges = useCallback((response: CouchDBChanges | EventMessage): Array<CouchDBChangeResult> => {
    if ((response as CouchDBChanges).results) {
      const couchDbChanges = response as CouchDBChanges;
      return couchDbChanges.results;
    }
    const realtimeChanges = response as EventMessage;
    return realtimeChanges.data as Array<CouchDBChangeResult>;
  }, []);

  const syncProceduresMetadata = useCallback(
    async (response?: CouchDBChanges | EventMessage): Promise<void> => {
      try {
        const changes = response && getChanges(response);
        if (changes) {
          // Dispatch a sync with only the changed docs.
          const docs = couchdbUtil.getChangedDocs(changes);
          // @ts-ignore, TODO convert proceduresSlice.js to Typescript
          await dispatch(
            fetchProceduresMetadata({
              services,
              docs,
            })
          );
        } else {
          // Dispatch a full sync of the procedure metadata.
          /* @ts-ignore, TODO convert proceduresSlice.js to Typescript */
          await dispatch(fetchAllProceduresMetadata({ services }));
        }
      } catch {
        // Ignore errors, we may be offline.
      }
    },
    [services, dispatch, getChanges]
  );

  const isPostgresReadWriteProceduresEnabled = useCallback(async () => {
    const enabledModules = await services.settings.getEnabledModules();
    return enabledModules.includes(POSTGRES_READ_WRITE_PROCEDURES_ENABLED);
  }, [services.settings]);

  useEffect(() => {
    if (!services.procedures || !realtimeService) {
      return;
    }

    let observer: Observer;

    void isPostgresReadWriteProceduresEnabled()
      .then((enabled) => {
        if (enabled) {
          observer = realtimeService.onProceduresEvent(syncProceduresMetadata);
        } else {
          observer = services.procedures.onProceduresChanged(syncProceduresMetadata);
        }
      })
      .catch(() => {
        /* no-op */
      });
    syncProceduresMetadata().catch((err) => apm.captureError(err));

    return () => {
      if (observer) {
        observer.cancel();
      }
    };
  }, [services, realtimeService, syncProceduresMetadata, isPostgresReadWriteProceduresEnabled]);

  useEffect(() => {
    if (!userInfo.session) {
      return;
    }
    const teamIds = getAllTeamIdsFromSession(userInfo.session);
    const socket = io(`${API_URL}/roomListeners`, {
      auth: {
        token: `Bearer ${userInfo.session.token}:${userInfo.session.password}`,
      },
      transports: ['websocket'],
    });

    // Set up initial services to allow for initial sync and testing
    let initialRealtimeServices = createRealtimeServices(teamIds, socket);
    setRealtimeServices(initialRealtimeServices);

    socket.on('connect', () => {
      initialRealtimeServices = createRealtimeServices(teamIds, socket);
      setRealtimeServices(initialRealtimeServices);
    });
    socket.on('disconnect', (reason) => {
      setRealtimeServices(undefined);
    });

    return () => {
      socket.close();
    };
  }, [userInfo.session, userInfo.session?.userOrgData]);

  return (
    <RealtimeContext.Provider
      value={{
        realtimeService,
      }}
    >
      {children}
    </RealtimeContext.Provider>
  );
};

const useRealtimeContext = () => {
  const context = useContext(RealtimeContext);
  if (context === undefined) {
    throw new Error('useRealtimeContext must be used within a TeamsProvider');
  }
  return context;
};

export { RealtimeProvider, useRealtimeContext };
