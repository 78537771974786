import { Socket } from 'socket.io-client';
import Counter from '../lib/counter';
import onNetworkChanged from './lib/network';
import {
  EntityType,
  EventMessage,
} from 'shared/lib/types/realtimeUpdatesTypes';
import {
  SocketIoActions,
  getEmitEventName,
  getRoomEventName,
} from 'shared/lib/realtimeUpdates';

export type Observer = {
  cancel: () => void;
};

export type Callback = (data: EventMessage) => void;

class RealtimeService {
  private teamId: string;
  private socket: Socket;
  private listenersForRoom: Counter;

  constructor(teamId: string, socket: Socket) {
    this.teamId = teamId;
    this.socket = socket;
    this.listenersForRoom = new Counter();
  }

  observeEvent(
    callback: Callback,
    entityType: EntityType,
    entityId?: number | string
  ): Observer {
    const eventListener = this.addEventListener(callback, entityType, entityId);
    const networkListener = addNetworkListener(callback);

    const cancel = () => {
      eventListener.cancel();
      networkListener.cancel();
    };

    return { cancel };
  }

  /*
   * Only use this pattern as necessary, e.g., for storing data in redux.
   *
   * Prefer the useRealtimeUpdates hook instead.
   */
  onUsersEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'users');
  }

  onOperatorRoleEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'operator_roles');
  }

  onTagsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'tags');
  }

  onUnitEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'units');
  }

  onProjectsEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'projects');
  }

  onProceduresEvent(callback: Callback): Observer {
    return this.observeEvent(callback, 'procedures');
  }

  private addEventListener(
    callback: Callback,
    entityType: EntityType,
    entityId?: string | number
  ): Observer {
    const roomName = this.roomName(entityType, entityId);

    // join room if not already joined
    if (!this.listenersForRoom.has(roomName)) {
      this.joinRoom(entityType, entityId);
    }

    // add listener
    const eventName = getEmitEventName({ entityType, entityId });
    this.socket.on(eventName, callback);

    // increment number of listeners for room
    this.listenersForRoom.add(roomName);

    const cancel = () =>
      this.removeEventListener(callback, entityType, entityId);
    return { cancel };
  }

  private removeEventListener(
    callback: Callback,
    entityType: EntityType,
    entityId?: string | number
  ): void {
    const roomName = this.roomName(entityType, entityId);

    // remove listener
    const eventName = getEmitEventName({ entityType, entityId });
    this.socket.off(eventName, callback);

    // decrement number of listeners for room
    this.listenersForRoom.remove(roomName);

    // leave room if room has no more listeners
    if (!this.listenersForRoom.has(roomName)) {
      this.leaveRoom(entityType, entityId);
    }
  }

  private joinRoom(entityType: string, entityId?: string | number) {
    const payload = {
      teamId: this.teamId,
      entityType,
      entityId,
    };
    this.socket.emit(SocketIoActions.joinRoom, payload);
  }

  private leaveRoom(entityType: string, entityId?: string | number) {
    const payload = {
      teamId: this.teamId,
      entityType,
      entityId,
    };

    this.socket.emit(SocketIoActions.leaveRoom, payload);
  }

  private roomName(entityType: string, entityId?: string | number): string {
    return getRoomEventName({
      teamId: this.teamId,
      entityType,
      entityId,
    });
  }
}

const addNetworkListener = (callback: Callback): Observer => {
  const notifyBackOnline = ({ online }: { online: boolean }): void => {
    if (online) {
      callback({ action: 'BACK_ONLINE', data: {} });
    }
  };
  return onNetworkChanged(notifyBackOnline);
};

export default RealtimeService;
